@font-face {
  font-family: "MerkurSansRegular";
  src: url("../public/css/Webfonts/EOT/MerkurSans-Regular-web.eot");
  src: url("../public/css/Webfonts/WOFF/MerkurSans-Regular-web.woff") format("woff"),
  url("../public/css/Webfonts/WOFF2/MerkurSans-Regular-web.woff2") format("woff2"),
  url("../public/css/Webfonts/OTF/MerkurSans-Regular-web.otf") format("opentype");
  }
  
@font-face {
  font-family: "MerkurSansBlack";
  src: url("../public/css/Webfonts/EOT/MerkurSans-Black-web.eot");
  src: url("../public/css/Webfonts/WOFF/MerkurSans-Black-web.woff") format("woff"),
  url("../public/css/Webfonts/WOFF2/MerkurSans-Black-web.woff2") format("woff2"),
  url("../public/css/Webfonts/OTF/MerkurSans-Black-web.otf") format("opentype");
  }

@font-face {
  font-family: "MerkurSansCondensedBlack";
  src: url("../public/css/Webfonts/EOT/MerkurSans-CondensedBlack-web.eot");
  src: url("../public/css/Webfonts/WOFF/MerkurSans-CondensedBlack-web.woff") format("woff"),
  url("../public/css/Webfonts/WOFF2/MerkurSans-CondensedBlack-web.woff2") format("woff2"),
  url("../public/css/Webfonts/OTF/MerkurSans-CondensedBlack-web.otf") format("opentype");
  }
body {
  font-size: 24px;
  background-color: rgb(1, 26, 63);
  font-family: 'MerkurSansRegular', Arial, sans-serif;
  font-weight:normal;
  font-style:normal;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}

button,
textarea,
input,
select,
.btn,
.chest,
.hitTarget,
a{
 -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
 -webkit-tap-highlight-color: transparent;
 -webkit-user-select: none;
 -khtml-user-select: none;
 -moz-user-select: none;
 -ms-user-select: none;
  user-select: none;
}
.App {
  text-align: center;
  position: relative;
  min-width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
main {
  max-height: 98vh;
  height: 100%;
  min-width: 50%;
  width: auto;
  margin: auto;
  position: relative;
  overflow: hidden;
  /* overflow-x: hidden; */
}

.mobile main {
  width: 100%;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  position: relative;
  background-color: #282c34;
  /* min-height: 100vh; */
}

.App-link {
  color: #61dafb;
}


.animation-container {
  background-color: red;
}
.level-animation-container {
  position: relative;
  background-color: #282c34;
  top: 0;
  left: 0;
  right: 0;
  z-index: 0;
}

.intro-heading {
    position: absolute;
    z-index: 150;
    left: 0;
    right: 0;
    width: 60%;
    top: 15%;
    margin: auto;
}
.desktop-view {
  width: 85%;
  margin: 0 auto;
}
.desktop-view,
.mobile-view {
  position: relative;
}
.mobile-view .intro-heading {
  width:80%;  
  top: 12%;
}
.intro-heading-totalbonus {
  font-size: clamp(16px, 6vw, 50px);
  color: #ffcb05;
  font-weight: lighter;
}
.continueSession-msg {
  
  opacity: 0;
  transition: opacity 2.5s ease-in-out;
  position: absolute;
  z-index: 150;
  left: 0;
  right: 0;
  /* min-width: 320px; */
  min-width: clamp(220px,90vw,640px);
  width: 60%;
  top: 35%;
  margin: auto;  
  color: #fff;
  font-size: 34px;
  font-weight: bolder;
  opacity: 0;
}
.intro-heading-title {
  color: #fff;
  font-size: 34px;
  font-weight: bolder;
  display: none;
}
.intro-heading-logo img {
  max-width: 200px;
  display: none;
}

.c-elements {
  position: absolute;
}

.chests {
  display: flex;
  position: absolute;
  z-index: 100;
  width: 100%;
  top: 37%;
  left: -4vw;
  flex-direction: column;
  align-items: center;
  transform: scale(0.75);
}


.chests-container {
  display: flex;
}
.chest-wrapper {
  opacity: 0;
  animation: fadeIn 2s 2s ease-out;
  animation-fill-mode: forwards;
  z-index: 1;
}
.chest-wrapper .points {
  /* background-color: greenyellow; */
  
  font-family: 'MerkurSansBlack', Arial, sans-serif;
  font-weight: bold;
  position: absolute;
  color: #fff;
  font-size: min(max(40px,3.2rem),90px);
  /* transform: translateY(-430px); */
  opacity: 0;
  display: block;
  transition: bottom 0.5s ease 2.5s, opacity 0.5s ease-out 2.5s;
  z-index: 100;
  text-align: center;
  left: 2%;
  right: 5rem;
  line-height: 1.25rem;
  bottom: 0;
}
.chest-wrapper .points.negative {
  color: #da0303;
  font-size: min(max(30px,2.5rem),70px);
  bottom: 35% !important;
}
.mobile-view .chest-wrapper .points.negative {
  bottom: 33% !important;
  transform: scale(1);
}
.mobile-view .points {
  font-size: min(max(20px,1rem),38px) !important;
  line-height: clamp(8px, 2vw, 20px);
  right: 25%;
  transform: scale(1.2);
}
.mobile-view .points .small-text {
  font-size: clamp(8px, 2vw, 20px);
}


.small-text {
  font-size: 24px;
  font-weight: 500;
  
  font-family: 'MerkurSansRegular', Arial, sans-serif;
  font-weight:normal;
}
.chest-wrapper .points.show { 
  /* transform:  translateY(-290px); */
  bottom: 38%;
  
  display: block;
  /* margin-top: 200px; */
  opacity: 1;
}
.upper-column .points.show {
  display: block;
  bottom: 38%;
  left: 0;
  opacity: 1;
}
.upper-column .points.show.negative {
  bottom: 35% !important;
  left: 1%;
}
.mobile-view .upper-column .points.show {
  bottom: 38% !important;
  left: 0;
  transform: scale(1.2);
}
.mobile-view .upper-column .points.show.negative {
  bottom: 32% !important;
  left: 0;
  transform: scale(1);
}
.chest-wrapper .hide  .finger-container {
  opacity: 0;
}
.chest-wrapper .finger-container {
  transition: opacity 0.5s ease-out 0.3s;
}
.kovcheg-anim_full {
  bottom: 0px;
  margin: -8px;
}

.upper-column {
  width: 67%;
  position: absolute;
  z-index: 2;
  height: 68%;
  /* goverflow: hidden; */
}
.mobile-view .upper-column {
  width: 65%;
}
.chest {
  /* background-color: red;   */
  transform: scale(1);
  display: flex;
}
.chest.k1, .chest.k2 {
  margin-left: 7%;
  margin-right: -7%;
  margin-top: -28%;
  margin-bottom: 28%;
}
.hitTarget {
  z-index: 1000;
  cursor: pointer;
  position: absolute;
  left: 35%;
  right: 0;
  bottom: 3%;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  background-color: rgba(128, 255, 0, 0);
  /* max-height: 5vw; */
  height: 23%;
}

.mobile-view .chest .hitTarget {
  z-index: 1000;
  cursor: pointer;
  position: absolute;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  background-color: rgba(128, 255, 0, 0);
  height: 25%;
}


.mobile-view .chests {
  transform: scale(0.95);
  top: 52%;
  left: -5%;
}
.mobile-view .chest.k1, .mobile-view .chest.k2 {
  margin-left: 10%;
  margin-right: -10%;
  margin-top: -30%;
  margin-bottom: 30%;
}
.mobile-view .chest .percent {
  /* font-size: 6px; */
}

.chest  .false {
  display: none;
}

.finger-container {
  position: absolute;
  width: 20%;
  top: 30%;
  left: 61%;
}
.mobile-view .finger-container {
  transform: scale(0.75);
}

.button_getbonus,
.button_endgame,
.button_next,
.button_getbonus_container {
  position: absolute;
  bottom: 0px;
  right: 10px;
  z-index: 200;
  /* max-width: 30%; */
}
.button_next {
  /* animation-name: swing-lr;
  animation-duration: 1s;  
  animation-iteration-count: infinite; */
}

.mobile-view .btn {
  /* max-height: 26px; */
  /* position: relative; */
  /* bottom: 30px; */
  width: 25vw;
  margin: 0 auto;
  margin-top: -10px;
}
.mobile-view .btn svg {
  max-height: 60px;
  width: auto;

}
.mobile-view .btn img {
  max-height: 30px;
  width: auto;
}
.mobile-view .finalPoints {
  top: 23%;
}
.button_getbonus {
  
}
.button_check_account {
  width: 100%;
  position: absolute;
  bottom: 20px;
  right: 0px;
  left: 0;
  z-index: 200;
  text-align: center;
}
.button_start {
  width: 100%;
  position: absolute;
  bottom: 500px;
  right: 0px;
  left: 0;
  z-index: 200;
  text-align: center;
}
.button_getbonus img,
.button_start img {
  max-width: 240px;
}
.button_check_account img {
  max-width: 340px;
}
.continue_btn,
.welcome-scr-animation-container .btn {
  position: absolute;
  text-align: center;
  top: 47%;
  left: 0;
  right: 0;
  z-index: 2000;
  height: 7%;
  width: 44%;
  margin: 0 auto;
}
.start_btn {
  opacity: 0;
  transform: translateY(300px);
  transition: all 0.5s ease;
  cursor: pointer;
  margin: 0 auto;
}
.start_btn.show {
  opacity: 1;
  transform: translateY(0);
  height: 100%;
}

.start_btn {
  max-width: 310px;
}
.c-container {
  position: relative;
}
.c-container1 {
  display: none;
}
.c-container .finalPoints {  
  align-content: stretch;

  color: #fff;
  font-family: MerkurSansCondensedBlack,Arial,sans-serif;
  font-size: clamp(3.8rem,10vw,10rem);
  font-weight: bolder;
  height: 100%;
  left: 0;
  line-height: 1.8rem;
  opacity: 0;
  position: absolute;
  right: 0;
  text-align: center;
  top: 26%;
  width: 100%;
  z-index: 300;
}
.desktop-view .c-container .finalPoints { 
  animation: fadeIn 0.5s ease-out 5.5s;
  animation-fill-mode: forwards;
}
.mobile-view .c-container .finalPoints {
  line-height: 1.4rem !important;
  animation: fadeIn 0.5s ease-out 4.5s;
  animation-fill-mode: forwards;
  top: 26% !important;
  /* animation: fadeIn 0.5s ease-out 4.5s; */
}

.c-container .small-text {
  font-size: 2rem;
}

.mobile-view .c-container .small-text {
  font-size: 1.4rem !important;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.session-end {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 10000;
}
.btn {
  background-color: transparent;
  border: none;
  bottom: 0;
  /* cursor: pointer; */
}
.btn img {
  width: auto;
  height: 100%;
  max-height: 40px;
}
.endScr-msg {
  font-size: 24px;  
  font-family: 'MerkurSansRegular', Arial, sans-serif;
  color: white;
  
  position: absolute;
  left: 0;
  right: 0;
  bottom: 20px;
  z-index: 10000;
}
.endScr-msg > div {
  max-width: 780px;
  margin: auto;
  padding: 10px;
}

@media only screen and (min-width: 1921px) and (max-width: 2200px){
  .upper-column {
    width: 58%;
  }
  .chests {
    top: 41%;
    left: -3%;
  }
}

@media only screen and (min-width: 2201px){
  .upper-column {
    width: 50%;
  }
  .chests {
    top: 42%;
    left: -3%;
  }
}

@media only screen and (max-width: 768px){
  /* .chests-container {
    margin: auto 45px auto 40px;
    
  }
  .chest-wrapper {
    margin-bottom: -25px;
  } */
  .endScr-msg {
    font-size: 14px;
  }
}

@keyframes swing-lr {
  0% {
    transform: translateX(0%);
  }
  50% {
    transform: translateX(-20%);
  }
  100% {
    transform: translateX(0%);
  }
}

.loaderIcon {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20000;
  
  background-color: rgba(2, 20, 41, 0.658);
}
.continue-game {
  position: relative;
  /* background-color: rebeccapurple; */
}
.mobile-view .continue-game .continueSession-msg {
  font-size: 20px;
}

 .hide-msg {
  opacity: 0;
}
.show-msg {
  opacity: 1;
}
/*.btn-hide {
  display: none;
}
.show {
  opacity: 1;
  display: block;
} */

.end-screen-mobile {
  margin-top: -5%;
}